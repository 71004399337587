<template>
  <div class="step3 apply" :id="`${component.name}Step3`">
    <a class="btn btn-bordered-secondary download font-sm" :href="`/v2/api/reward/projects/${form.new.projectSeq}/story/download-all`" target="_blank" rel="noopener noreferrer" v-if="isAdminPage">이미지 전체
      다운로드</a>
    <div class="form-group" v-for="(t,idx) in tabs" :key="idx">
      <div class="guide">
        <div class="title">
          <span>{{ t.title }}</span>
        </div>
        <div class="desc">
          <span>{{ t.desc }}</span>
        </div>
        <a class="color-point font-sm pointer" @click="openExample(t.name, t.title)" v-if="!t.hideExample">{{ t.guide || "더 많은 예시보기" }}</a>
      </div>
      <template v-if="state.loaded">
        <div class="content" v-if="t.name === 'fundingInfo'">
          <div class="input">
            <label :for="`${component.name}ProjectName`" class="subject">
              <span class="color-point">*</span>
              <span> 프로젝트 제목</span>
              <span class="font-xs"> ({{ form.new.projectName ? form.new.projectName.trim().length : 0 }}/30)</span>
            </label>
            <input :id="`${component.name}ProjectName`" type="text" class="form-control border-focus-point" :maxlength="limits[t.name].title" :value="form.new.projectName" @input="form.new.projectName = $event.target.value"
                   @keyup.enter="save()" placeholder="후원자의 눈길을 사로잡을 수 있는 멋진 제목을 지어주세요."/>
          </div>
          <div class="input">
            <label :for="`${component.name}SimpleText`" class="subject">
              <span class="color-point">*</span>
              <span> 프로젝트 간략 소개</span>
              <span class="font-xs"> ({{ form.new.simpleText ? form.new.simpleText.length : 0 }}/20)</span>
            </label>
            <input :id="`${component.name}SimpleText`" type="text" class="form-control border-focus-point" :maxlength="limits[t.name].subTitle" :value="form.new.simpleText" @input="form.new.simpleText = $event.target.value"
                   @keyup.enter="save()"
                   placeholder="제목 아래 간략하게 소개될 한 줄 요약을 작성해주세요."/>
          </div>
          <RewardApplyImageSlide title="프로젝트를 대표할 이미지를 업로드해주세요. 메인 썸네일로 나타납니다."
                                 :desc="`권장 비율: 4 : 3 / 권장 크기: ${$definitions.thumbnail.width}px * ${$definitions.thumbnail.height}px / 형식: jpg, png / 최대 크기: ${$definitions.limits.maxFileSize}`"
                                 :required="true"
                                 :limit="limits[t.name].image"
                                 :mainExist="true"
                                 :files.sync="files.new.mainImages"
                                 :delFiles="files.deletes"
                                 :componentNameSuffix="t.name"
          />
          <div class="preview" v-if="files.new.mainImages.length">
            <div class="subject">
              <span>썸네일 미리보기</span>
              <span class="d-block font-xs color-secondary">이미지안 주요 요소들이 잘리거나 좌우 여백이 생기지 않는지 확인해주세요.</span>
            </div>
            <div class="thumbs">
              <div class="ratio-11">
                <span class="img" :style="{backgroundImage: `url(${getPreviewImage(files.new.mainImages.find(m => m.main))})`}"></span>
              </div>
              <div class="ratio-43">
                <span class="img" :style="{backgroundImage: `url(${getPreviewImage(files.new.mainImages.find(m => m.main))})`}"></span>
              </div>
            </div>
            <div class="desc">
              <b class="font-sm color-secondary">목록에서 보여질 때</b>
              <b class="font-sm color-secondary">상세에서 보여질 때</b>
            </div>
          </div>
          <div class="video">
            <div class="top">
              <label class="subject">
                <span>동영상을 대표 이미지로 사용 하시겠습니까?</span>
                <div class="font-xs color-secondary">
                  <span>상세 페이지 대표 이미지에 동영상만 노출</span>
                  <span class="pl-1">(YouTube 동영상만 업로드 가능합니다.)</span>
                </div>
              </label>
              <div class="right">
                <div class="form-check">
                  <input class="form-check-input" type="radio" :name="`${component.name}VideoFlagY`" :id="`${component.name}VideoFlagY`" :value="true" v-model="state.video" @change="setVideoFlag()">
                  <label class="form-check-label" :for="`${component.name}VideoFlagY`">네</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" :name="`${component.name}VideoFlagN`" :id="`${component.name}VideoFlagN`" :value="false" v-model="state.video" @change="setVideoFlag()">
                  <label class="form-check-label" :for="`${component.name}VideoFlagN`">아니요</label>
                </div>
              </div>
            </div>
            <div class="bottom" v-if="state.video">
              <img class="thumbnail" :src="`https://img.youtube.com/vi/${computedYoutubeVideoId}/0.jpg`" alt="유튜브 영상" v-if="isYoutubeUrl(form.new.videoUrl)">
              <input :id="`${component.name}VideoUrl`" type="text" class="form-control border-focus-point font-sm" placeholder="동영상 URL을 입력해주세요." v-model="form.new.videoUrl" @keyup.enter="save()"/>
            </div>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'fundingIntroTemplate'">
          <div class="select">
            <div class="wrapper" :class="{selected: form.new.newEditorFlag !== 'Y'}" @click="() => form.new.newEditorFlag = 'N'">
              <span class="img" style="background-image:url('/assets/img/page.apply.reward.step3.funding.intro.templete.blog.type.svg')"></span>
              <span class="font-sm">블로그 형</span>
            </div>
            <div class="wrapper" :class="{selected: form.new.newEditorFlag === 'Y'}" @click="() => form.new.newEditorFlag = 'Y'" v-if="form.new.simulationFlag !== 'Y'">
              <span class="img" style="background-image:url('/assets/img/page.apply.reward.step3.funding.intro.templete.category.type.svg')"></span>
              <span class="font-sm">카테고리 형</span>
            </div>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'fundingIntro'" :class="t.name">
          <div class="wrapper" v-for="(f, idx) in state.story[t.name]"
               :key="f.key">
            <span class="pointer font-sm color-secondary right-act" v-if="state.story[t.name].length > 1" @click="removeItem(t.name, idx)">삭제</span>
            <RewardApplyImageSlide :section="t.name"
                                   title="프로젝트를 소개하는 이미지를 업로드해주세요."
                                   :desc="`가로 이미지 권장 / 형식: jpg, png / 최대 크기: ${$definitions.limits.maxFileSize}`"
                                   :required="true"
                                   :limit="limits[t.name].image"
                                   :files.sync="f.files"
                                   :delFiles="state.delFiles"
                                   :componentNameSuffix="t.name + f.key"
            />
            <RewardApplyTextSection
                :editorKey="keys.editor"
                :titleValue.sync="f.title"
                :contentValue.sync="f.content"
                :title="{
                text: '프로젝트 소개 제목',
                name: `FundingIntroTitle${f.key}`,
                required: true,
                placeholder: '스토리 상세내용을 소개하는 첫 문구를 작성해주세요.',
                maxLength: limits[t.name].title,
             }"
                :content="{
                text: '프로젝트 소개 내용',
                name: `FundingIntroContent${f.key}`,
                required: true,
                placeholder:'스토리에 처음을 장식할 내용을 작성해주세요.',
                maxLength: limits[t.name].content,
                height: 350,
            }"/>
          </div>
          <div class="add-item">
            <a class="color-anchor pointer font-sm" v-if="state.story[t.name].length < 10" @click="addItem(t.name)">항목 추가 +</a>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'oldFundingIntro'">
          <div class="wrapper">
            <TinyEditor :id="`${component.name}ProjectInfo`" :value.sync="form.new.projectInfo"
                        ref="editorRef" :key="keys.editor"
                        placeholder="더 상세하게 리워드를 소개해 주세요. 특별한 점, 구매해야 하는 이유 등 자유롭게 작성해 주세요."
                        page="apply step3"/>
          </div>
        </div>
        <div class="content" :class="t.name" v-else-if="t.name=== 'fundingPoint'" ref="fundingPointRef" :data-section="t.name">
          <div class="point" v-for="(p, idx) in state.story[t.name]" :key="t.name + idx" :class="t.name" :data-idx="idx">
            <div class="subject">
              <label :for="`${component.name}KeyPoint${idx}`">
                <span class="color-point" v-if="idx < fundingPointMinRequired">* </span>
                <span>포인트 {{ idx + 1 }}</span>
                <span class="color-secondary font-xs pl-2" v-if="limits[t.name].content">({{ p.content.length || 0 }}/{{ limits[t.name].content }})</span>
              </label>
              <span class="pointer font-sm color-secondary" v-if="state.story[t.name].length > fundingPointMinRequired && idx + 1 > fundingPointMinRequired" @click="removeItem(t.name, idx)">삭제</span>
            </div>
            <div class="input">
              <input type="text" :id="`${component.name}KeyPoint${idx}`" v-model="p.content" class="form-control border-focus-point" placeholder="스토리 또는 리워드에 대한 핵심 포인트를 작성해 주세요." :maxlength="limits[t.name].content">
              <span class="handle"><i class="fa fa-sort"></i></span>
            </div>
          </div>
          <div class="add-item">
            <a class="color-anchor pointer font-sm" v-if="state.story[t.name].length < 5" @click="addItem(t.name)">항목 추가 +</a>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'rewardIntro'" :class="t.name">
          <div class="wrapper" v-for="(r, idx) in state.story[t.name]" :key="r.key">
            <span class="pointer font-sm color-secondary right-act" v-if="state.story[t.name].length > 1" @click="removeItem(t.name, idx)">삭제</span>
            <RewardApplyImageSlide :section="t.name"
                                   title="리워드를 소개하는 이미지를 업로드해 주세요."
                                   :desc="`가로 이미지 권장 / 형식: jpg, png / 최대 크기: ${$definitions.limits.maxFileSize}`"
                                   :required="true"
                                   :limit="limits[t.name].image"
                                   :files.sync="r.files"
                                   :delFiles="state.delFiles"
                                   :componentNameSuffix="t.name + r.key"
            />
            <RewardApplyTextSection
                :editorKey="keys.editor"
                :titleValue.sync="r.title"
                :contentValue.sync="r.content"
                :title="{
                  text: '리워드 소개 제목',
                  name: `RewardIntroTitle${r.key}`,
                  required: true,
                  placeholder: '리워드를 매력적으로 소개할 수 있는 제목을 작성해 주세요.',
                  maxLength: limits[t.name].title,
                }"
                :content="{
                  text: '리워드 소개 내용',
                  name: `RewardIntroContent${r.key}`,
                  required: true,
                  placeholder:'더 상세하게 리워드를 소개해 주세요. 특별한 점, 구매해야 하는 이유 등 자유롭게 작성해 주세요.',
                  maxLength: limits[t.name].content,
                  height: 350,
                  }"/>
          </div>
          <div class="add-item">
            <a class="color-anchor pointer font-sm" v-if="state.story[t.name].length < 10" @click="addItem(t.name)">항목 추가 +</a>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'rewardConstructor'" :class="t.name" ref="rewardConstructorRef" :data-section="t.name">
          <div class="row font-sm lead">
            <div class="col-7">리워드 구성</div>
            <div class="col-3">가격</div>
            <div class="col-2 order">순서</div>
          </div>
          <div class="row reward" v-for="(r, idx) in form.new.rewardItems" :key="t.name + idx" :data-idx="idx">
            <div class="col-7">
              <input type="text" class="form-control" :value="r.title" disabled>
            </div>
            <div class="col-3">
              <input type="text" class="form-control" :value="r.rewardAmt" disabled>
            </div>
            <div class="col-2 order">
              <span class="handle"><i class="fa fa-sort"></i></span>
            </div>
          </div>
        </div>
        <div class="content" v-else-if="t.name === 'plan'">
          <RewardApplyTextSection
              :editorKey="keys.editor"
              :hideTitle="true"
              :contentValue.sync="state.story[t.name][0].content"
              :content="{
              text: '후원금 사용계획 내용',
              name: 'Plan',
              required: true,
              placeholder:'후원금을 어떻게 사용할 예정인지 작성해 주세요.',
              maxLength: limits[t.name].content,
              height: 350,
          }"/>
        </div>
        <div class="content" v-else-if="t.name === 'makerIntro'">
          <RewardApplyTextSection
              :editorKey="keys.editor"
              :hideTitle="true"
              :contentValue.sync="state.story[t.name][0].content"
              :content="{
              text: '진행자 소개 내용',
              name: 'MakerIntroContent',
              placeholder:'프로젝트를 진행하는 진행자분이 궁금해요. 어떤 일을 하고 있는지 앞으로 어떤 일을 하고 싶은지 등 자유롭게 작성해 주세요.',
              required: true,
              maxLength: limits[t.name].content,
              height: 350,
          }"/>
        </div>
        <div class="content" v-else-if="t.name === 'summery'">
          <div class="wrapper">
            <RewardApplyTextSection
                :editorKey="keys.editor"
                :titleValue.sync="state.story[t.name][0].title"
                :contentValue.sync="state.story[t.name][0].content"
                :content="{
                  text: '프로젝트 요약 정보 내용',
                  name: `SummeryContent`,
                  required: true,
                  placeholder:'위 작성된 내용들을 제외하고 추가로 넣고 싶은 내용을 작성해주세요',
                  maxLength: limits[t.name].content,
                  height: 350,
                }"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import TinyEditor from "@/components/TinyEditor";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import RewardApplyImageSlide from "@/components/RewardApplyImageSlide.vue";
import SimpleTinyEditor from "@/components/SimpleTinyEditor.vue";
import RewardApplyTextSection from "@/components/RewardApplyTextSection.vue";
import Phone from "@/components/Phone.vue";
import rewardStoryTabs from "@/scripts/rewardStoryTabs";
import Sortable from "sortablejs";

function Component(initialize) {
  this.name = "pageApplyNewRewardStep3";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    trigger: Boolean,
    save: Function,
    warn: Function,
    keys: Object,
    tabs: Array,
    isAdminPage: Boolean,
  },
  components: {Phone, RewardApplyTextSection, SimpleTinyEditor, RewardApplyImageSlide, TinyEditor},
  setup(props, {emit}) {
    const component = new Component(() => {
      if (props.form.new.tag) {
        for (let t of lib.getSplit(props.form.new.tag, ["\r\n", "\r", "\n", ","])) {
          t && t.trim() && state.tags.push(t.replaceAll("#", "").trim());
        }
      }

      state.video = props.form.new.videoFlag === "Y";

      props.validators[3] = () => {
        if (props.form.new.simulationFlag === "Y" && props.form.new.newEditorFlag === "Y") {
          return props.warn("모의투자는 블로그 형으로 신청해야 합니다.");
        }

        if (!props.form.new.projectName?.trim()) {
          return props.warn("프로젝트 제목을 입력해주세요.", `${component.name}ProjectName`);
        } else if (!props.form.new.simpleText?.trim()) {
          return props.warn("프로젝트 간략 소개 내용을 입력해주세요.", `${component.name}SimpleText`);
        } else if (!props.files.new.mainImages.length) {
          return props.warn("프로젝트 대표 이미지를 올려주세요.");
        } else if (!props.files.new.mainImages.some(m => m.main)) {
          return props.warn("프로젝트 대표 이미지를 설정해주세요.");
        }

        if (props.files.new.mainImages.length) {
          for (let f in props.files.new.mainImages) {
            let mainImageTotalLen = 0;

            for (let i in props.files.new.mainImages) {
              if (props.files.new.mainImages[i].size) {
                if (props.files.new.mainImages[i].main && (props.files.new.mainImages[i].size > maxFileSize || props.files.new.mainImages[i].size > maxRequestSize)) {
                  return props.warn(`프로젝트 대표 이미지의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
                } else if (props.files.new.mainImages[i].size > maxFileSize) {
                  return props.warn(`프로젝트 대표 이미지 ${i}번째 파일의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
                }

                mainImageTotalLen += props.files.new.mainImages[i].size;
              }
            }

            if (mainImageTotalLen > maxRequestSize) {
              return props.warn(`프로젝트 대표 이미지들의 용량이 너무 큽니다. 크기 조정 후 다시 업로드해주세요.`);
            }
          }
        }

        if (state.video) {
          if (!props.form.new.videoUrl?.trim()) {
            return props.warn("동영상 URL을 입력해주세요.", `${component.name}VideoUrl`);
          } else if (!lib.isValidUrl(props.form.new.videoUrl)) {
            return props.warn("동영상 URL 값이 유효하지 않습니다.", `${component.name}VideoUrl`);
          } else if (!isYoutubeUrl(props.form.new.videoUrl)) {
            return props.warn("YouTube 동영상만 업로드 가능합니다.", `${component.name}VideoUrl`);
          } else if (!props.form.new.videoUrl.startsWith("https://")) {
            props.form.new.videoUrl = "https://" + props.form.new.videoUrl.replace("http://", "");
          }
        }

        if (!validated()) {
          return;
        }

        return true;
      };

      http.get("/api/reward/categories").then(({data}) => {
        state.categories = data.body;
      });

      load();
    });

    const state = reactive({
      video: false,
      loaded: false,
      categories: [],
      tags: [],
      tagSuggestions: [],
      delFiles: [],
      delStories: [],
      story: {
        fundingIntro: [{category: "fundingIntro", title: "", content: "", files: [], ord: 0}],
        fundingPoint: [{category: "fundingPoint", title: "", content: "", files: [], ord: 0}],
        rewardIntro: [{category: "rewardIntro", title: "", content: "", files: [], ord: 0}],
        plan: [{category: "category", title: "", content: "", files: [], ord: 0}],
        makerIntro: [{category: "makerIntro", title: "", content: "", files: [], ord: 0}],
        summery: [{category: "summery", title: "", content: "", files: [], ord: 0}],
      },
    });

    const hashtagRef = ref();
    const editorRef = ref();
    const fundingPointRef = ref();
    const rewardConstructorRef = ref();
    const fundingPointMinRequired = 2;

    const limits = {
      fundingInfo: {
        image: 5,
        title: 30,
        subTitle: 20,
      },
      fundingIntro: {
        image: 7,
        title: 50,
        content: 1000,
      },
      fundingPoint: {
        content: 60,
      },
      rewardIntro: {
        image: 7,
        title: 50,
        content: 1000,
      },
      plan: {
        content: 900,
      },
      makerIntro: {
        content: 700,
      },
      summery: {
        title: 50,
        content: 700,
      }
    };

    const maxFileSize = lib.getMbToByte(definitions.limits.maxFileSize.replace("MB", ""));
    const maxRequestSize = lib.getMbToByte(definitions.limits.maxRequestSize.replace("MB", ""));

    const computedYoutubeVideoId = computed(() => {
      const regExp = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      return regExp.exec(props.form.new.videoUrl)[3];
    });

    const computedRewardItems = computed(() => {
      return props.form.new.rewardItems;
    });

    const load = () => {
      state.loaded = false;

      http.get(`/api/reward/projects/${props.form.new.projectSeq}/story`).then(({data}) => {
        state.loaded = true;

        for (let i in state.story) {
          state.story[i] = [];

          if (!data.body[i] || !data.body[i].length) {
            state.story[i].push(getBasicTemplate({
              category: i,
              content: getExample(i),
            }));
          } else {
            for (let j = 0; j < data.body[i].length; j++) {
              state.story[i].push(getBasicTemplate({
                id: data.body[i][j]?.id,
                category: data.body[i][j]?.category || i,
                title: data.body[i][j]?.title,
                content: data.body[i][j]?.content,
                files: data.body[i][j]?.files,
                ord: j,
              }));
            }
          }
        }

        if (state.story.fundingPoint.length < fundingPointMinRequired) {
          for (let i = state.story.fundingPoint.length; i < fundingPointMinRequired; i++) {
            state.story.fundingPoint.push(getBasicTemplate({category: "fundingPoint"}));
          }
        }
      });
    };

    const setSortable = ($listWrapper) => {
      const onEnd = () => {
        const $fundingPointItems = fundingPointRef?.value && fundingPointRef?.value[0]?.querySelectorAll(".point");
        const $rewardConstructorItems = rewardConstructorRef?.value && rewardConstructorRef?.value[0]?.querySelectorAll(".reward");

        switch ($listWrapper.dataset.section) {
          case "fundingPoint":
            for (let i = 0; i < $fundingPointItems.length; i += 1) {
              state.story.fundingPoint.find((f, idx) => idx?.toString() === $fundingPointItems[i].dataset.idx?.toString()).ord = i;
            }
            return;
          case "rewardConstructor":
            for (let i = 0; i < $rewardConstructorItems.length; i += 1) {
              computedRewardItems.value.find((r, idx) => idx?.toString() === $rewardConstructorItems[i].dataset.idx?.toString()).displayOrder = (i + 1).toString();
            }
            return;
        }
      };

      const onMove = (e) => {
        return e.related.className.indexOf("add-item") === -1 && e.related.className.indexOf("lead") === -1;
      };

      new Sortable($listWrapper, {
        handle: ".handle",
        filter: ".lead, .skeleton, .add-item",
        animation: 350,
        forceFallback: true,
        fallbackTolerance: 5,
        onEnd,
        onMove,
      });
    };

    const saveStep3 = async () => {
      const args = [];

      for (let i in state.story) {
        args.push(...state.story[i]);
      }

      for (let i in state.delStories) {
        args.push({...state.delStories[i], delFlag: "Y"});
      }

      http.put(`/api/reward/projects/${props.form.new.projectSeq}/story`, args).then(({data}) => {
        const uploads = [];

        for (let i in state.story) {
          for (let j in state.story[i]) {
            if (!state.story[i][j].files.length) {
              continue;
            }

            uploads.push(uploadFiles({sectionName: i, idx: j, id: data.body[i][j].id}));
          }
        }

        Promise.all(uploads).then(() => {
          load();
        });
      });
    };

    const uploadFiles = ({sectionName, idx = 0, id}) => {
      const formData = new FormData();
      const item = state.story[sectionName][idx];

      if (!item.files.length) {
        return;
      }

      for (let i in item.files) {
        if (item.files[i].id) {
          continue;
        }

        formData.append("files", item.files[i]);
      }

      return formData.has("files") && http.post(`/api/reward/projects/${props.form.new.projectSeq}/story/${id}/upload`, formData);
    };

    const validated = () => {
      if (props.form.new.newEditorFlag === "N") {
        return true;
      }

      const warn = ({id, message}) => {
        id && document.getElementById(`${id}`)?.focus();
        store.commit("setSwingMessage", message);
      };

      const tabs = rewardStoryTabs.step3;

      for (let i in state.story) {
        const sectionTitle = tabs[tabs.findIndex(t => t.name === i)]?.title || "STEP3 안의";

        for (let j = 0; j < state.story[i].length; j++) {
          switch (i) {
            case "fundingIntro":
            case "rewardIntro":
              if (!state.story[i][j].files || !state.story[i][j].files.length) {
                warn({message: `${sectionTitle} 사진을 업로드해주세요.`});
                return false;
              }

              if (!state.story[i][j].title || !state.story[i][j].title.trim()) {
                warn({id: `componentRewardApplyTextSection${i === "fundingIntro" ? "Funding" : "Reward"}IntroTitle${j}`, message: `${sectionTitle} 제목을 작성해주세요.`});
                return false;
              } else if (!state.story[i][j].content || !state.story[i][j].content.trim()) {
                warn({id: `componentRewardApplyTextSection${i === "fundingIntro" ? "Funding" : "Reward"}IntroContent${j}`, message: `${sectionTitle} 내용을 작성해주세요.`});
                return false;
              }

              break;

            case "fundingPoint":
              if ((!state.story[i][j].content || !state.story[i][j].content.trim())) {
                warn({id: `${component.name}KeyPoint${j}`, message: `${sectionTitle} 내용을 작성해주세요.`});
                return false;
              } else if (j === 1) {
                break;
              }

              break;
            case "plan":
              if (!state.story[i][j].content || !state.story[i][j].content.trim()) {
                warn({id: "componentRewardApplyTextSectionPlan", message: `${sectionTitle} 내용을 작성해주세요.`});
                return false;
              }

              break;

            case "makerIntro":
              if (!state.story[i][j].content || !state.story[i][j].content.trim()) {
                warn({id: "componentRewardApplyTextSectionMakerIntroContent", message: `${sectionTitle} 내용을 작성해주세요.`});
                return false;
              }

              break;

            case "summery":
              if (!state.story[i][j].content || !state.story[i][j].content.trim()) {
                warn({id: "componentRewardApplyTextSectionSummeryContent", message: `${sectionTitle} 내용을 작성해주세요.`});
                return false;
              }
              break;

            default:
              if (!state.story[i][j].title || !state.story[i][j].title.trim()) {
                store.commit("setSwingMessage", `${sectionTitle} 제목을 작성해주세요`);
                return false;
              } else if (!state.story[i][j].content || !state.story[i][j].content.trim()) {
                store.commit("setSwingMessage", `${sectionTitle} 내용을 작성해주세요`);
                return false;
              }

              break;
          }
        }
      }

      return true;
    };

    const openExample = (sectionName, sectionTitle) => {
      store.commit("openModal", {
        name: "RewardApplyExamples",
        params: {
          sectionName,
          sectionTitle,
        }
      });
    };

    const getBasicTemplate = ({id = null, category, title = "", content = "", files = [], ord = 0, delFlag = "N"}) => {
      return {
        id,
        category,
        title,
        content: content || (ord === 0 ? getExample(category) : ""),
        files,
        ord,
        delFlag,
        key: Math.floor(Math.random() * 1000).toString() + Math.random().toString(36).slice(2, 9)
      };
    };

    const getExample = (tab) => {
      switch (tab) {
        case "fundingIntro":
          return "<p>예시)</p>"
              + "<p>안녕하세요, 과거와 미래가 공존하는 광화문역 근처에서 작은 양식 레스토랑을 운영하는 오마이입니다. 뒤를 돌면 멋스럽게 남아 있는 옛 풍경과 앞을 보면 빠르게 변화하는 휘황찬란한 도시의 모습을 두루 갖춘 서울에 반해 이곳에 자리 잡은 지 벌써 8년이라는 세월이 흘렀어요. 주중에는 주변 직장인들이, 주말에는 관광객을 비롯해 데이트하는 커플, 나들이하는 가족까지 그동안 많은 분들이 저희 레스토랑에 찾아오셨습니다.</p>"
              + "<p>저희 레스토랑은 작은 공간이라 고객분들과 종종 대화할 기회가 생기곤 합니다. 그중 저희 음식을 너무 먹고 싶은데 거리가 멀어 자주 못 온다는 이야기가 계속 기억에 남더라고요. 그래서 바쁘다는 핑계로 미뤄 왔던 일을 이번 크라우드펀딩을 통해 실행해 보려고 해요. 매일 아침 공수하는 신선한 재료만을 사용하여 밀키트를 만들었습니다. 처음부터 끝까지 모두 직접 준비하고 만드는 만큼 완성도 높은 소량의 제품만 준비했어요. 고급 레스토랑의 맛을 쉽고 간편하게 드실 수 있도록 정성을 다했습니다. 이제 집에서 간편하게 즐겨 보세요!</p>";
        case "fundingPoint":
          return "";
        case "rewardIntro":
          return "<p>예시)</p>" +
              "<p>처음으로 선보이는 밀키트 ‘깻잎 페스토 파스타’는 저희 레스토랑의 대표 메뉴입니다. 향긋하고 부드러운 깻잎은 쌈, 장아찌, 나물 등으로 주로 즐겨 먹는데요. 우리 식탁에 자주 오르는 반찬으로 한국인이 가장 좋아하는 채소 중 하나입니다. 저희는 깻잎을 좀 더 색다르게 즐길 수는 없을까 고민하다가 건강에 좋은 가평 잣과 국내산 깻잎을 가득 넣어 더욱 고소하고 감칠맛 나는 페스토를 완성했어요. 매장에서 직접 만드는 생면과 조합은 매일 먹어도 절대 질리지 않는 맛이에요. 한국의 맛이 담긴 페스토를 경험하고 싶다면, 가볍지만 근사한 한 끼를 먹고 싶다면, 고급 레스토랑의 맛을 집에서도 느끼고 싶다면 지금 바로 펀딩에 참여해 보세요.</p>"
              + "<p>여기서 팁 하나!" +
              "<br/>삶은 면을 차가운 물에 헹궈 냉 파스타로도 즐겨보세요. 향긋한 깻잎 향을 더 진하게 느낄 수 있어서 별미예요.</p>";
        case "plan":
          return "<p>예시)</p>" +
              "<p>이번 크라우드펀딩을 통해 얻은 후원금으로 오마이 레스토랑의 다양한 밀키트를 개발할 예정입니다. 저희 레스토랑의 시그니처 메뉴인 깻잎 페스토 파스타와 곁들여 먹을 수 있는 에피타이저부터 메인 디쉬까지 쉽고 간단하지만 현장의 맛을 고스란히 느낄 수 있는, 소중한 사람에게 근사한 한끼 대접할 수 있는 그런 제품으로 찾아뵙겠습니다.</p>";
        case "makerIntro":
          return "<p>예시)</p>" +
              "<p>오마이 레스토랑은 단순한 레스토랑을 넘어 정성스러운 요리를 간편하게 즐길 수 있는 새로운 방식을 제안합니다. 저희는 신선한 재료와 간편한 조리 방법으로 집에서도 전문가의 맛을 느낄 수 있도록 오랜 시간 노력해 왔습니다. 많은 분들의 도움으로 저희 꿈이 현실이 되어 매우 행복하고 즐겁습니다. 앞으로 더 다양한 제품을 개발하여 여러분의 일상에 다채로운 맛을 더해줄 거라 기대합니다. 많은 관심과 지지 부탁드립니다.</p>";
        case "summery":
          return `<p><span>- 펀딩 기간: ${props.form.new.investStartDate ? lib.getDateFormat(props.form.new.investStartDate, "yyyy년 MM월 dd일") : "○○○○년 ○월 ○○일"} ~ ${props.form.new.investEndDate ? lib.getDateFormat(props.form.new.investEndDate,
                  "yyyy년 MM월 dd일") : "○○○○년 ○월 ○○일"}</span><br/>` +
              `<span>- 리워드 제공일: 펀딩 종료 후 ${props.form.new.rewardItems[0].rewardExpectText || "○○○○년 ○월 ○○일"}부터 순차 택배 발송</span><br/>` +
              `<span>- 리워드 배송비: ${props.form.new.deliveryAmt || 0}원</span><br/>` +
              "<span>(다만, 제주 및 도서산간 지역은 추가 배송비가 발생합니다. 결제 시 추가 참여금에 해당 금액을 입력해 주세요. 제주 및 도서산간지역: 4,000원)</span></p>" +
              "<p><span>문의처</span><br/>"
              + `<span>- 이메일: ${props.form.new.userMail || "example@example.com"}</span><br/>`
              + `<span>- 연락처: ${props.form.new.userHp || "02-000-0000"}</span></p>`;
        default:
          return "";
      }
    };

    const addItem = (tab) => {
      state.story[tab].push(getBasicTemplate({
        category: tab,
        ord: state.story[tab].length,
      }));
    };

    const removeItem = (tab, idx) => {
      if (tab === "fundingPoint" && state.story[tab].length <= fundingPointMinRequired) {
        return store.commit("setSwingMessage", `프로젝트 포인트는 ${fundingPointMinRequired}개까지 필수 작성 해야합니다.`);
      } else if (state.story[tab].length <= 1) {
        return;
      }

      if (state.story[tab][idx].id) {
        state.delStories.push(state.story[tab][idx]);
      }

      state.story[tab].splice(idx, 1);
    };

    const isYoutubeUrl = (url) => {
      if (!url) {
        return false;
      }

      const regExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
      return url.match(regExp);
    };

    const setVideoFlag = () => {
      props.form.new.videoFlag = state.video ? "Y" : "N";
    };

    const applyFromModal = (params) => {
      props.form.new.projectInfo = params.projectInfo;
      props.keys.editor += 1;
    };

    const openAI = () => {
      store.commit("openModal", {
        name: "ChatGPT",
        params: {
          page: "apply",
          title: props.form.new.projectName,
          category: props.form.new.projectCate,
        },
        callback: `${component.name}.applyFromModal`,
      });
    };

    const openExplanation = () => {
      store.commit("openModal", {
        name: "RewardApplyExamples",
        params: {
          invisibleExample: true,
          sectionName: "fundingIntroTemplate",
          sectionTitle: "프로젝트 소개 템플릿",
        }
      });
    };

    const getPreviewImage = (file) => {
      if (file === undefined || file === null) {
        return;
      }

      return file.type ? URL.createObjectURL(file) : file.filePath + file.fileSaveName;
    };

    watch(() => props.trigger, async (newValue) => {
      if (newValue) {
        saveStep3();

        emit("update:trigger", false);
      }
    });

    watch(() => {
      return {
        fundingPointRef: fundingPointRef?.value
        , rewardConstructorRef: rewardConstructorRef?.value
      };
    }, (next) => {
      if (next.fundingPointRef?.length) {
        setSortable(fundingPointRef?.value[0]);
      }

      if (next.rewardConstructorRef?.length) {
        setSortable(rewardConstructorRef?.value[0]);
      }
    });

    return {
      component,
      state,
      editorRef,
      hashtagRef,
      fundingPointRef,
      rewardConstructorRef,
      limits,
      computedYoutubeVideoId,
      fundingPointMinRequired,
      applyFromModal,
      addItem,
      removeItem,
      isYoutubeUrl,
      openAI,
      openExample,
      setVideoFlag,
      openExplanation,
      getPreviewImage,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step3 {
  display: flex;
  flex-direction: column;
  gap: $px70;

  > .download {
    position: absolute;
    top: $px-6;
    right: 0;
  }

  > .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px16;

      > .point {
        .subject {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .input {
          display: flex;
          gap: $px16;
          justify-content: space-between;
          align-items: center;

          .handle {
            cursor: pointer;
            padding: $px8 $px16;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
      }

      > .wrapper {
        display: flex;
        flex-direction: column;
        gap: $px16;
      }

      .add-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $px16;
        width: 100%;
      }

      .preview {
        .thumbs {
          display: flex;
          gap: $px32;
          align-items: center;

          .ratio-11, .ratio-43 {
            width: calc((100% - $px16) / 2);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: $px16;

            > .img {
              border: $px1 solid $colorBorder;
              width: 100%;
              background-size: auto 100%;
              background-position: center;
              background-color: #eee;
              border-radius: $px16;
            }
          }

          .ratio-11 {
            > .img {
              padding-top: 100%;
            }
          }

          .ratio-43 {
            > .img {
              padding-top: 75%;
            }
          }
        }

        > .desc {
          display: flex;
          align-items: center;
          gap: $px32;
          text-align: center;
          margin-top: $px16;

          > b {
            width: calc((100% - $px16) / 2);
          }
        }
      }

      .video {
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .right {
            display: flex;
            gap: $px16;
            font-size: $px14;
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;

          .thumbnail {
            align-self: center;
            margin-bottom: $px16;
            max-width: $px300;
          }
        }
      }

      .select {
        display: flex;
        align-items: center;
        gap: $px16;

        > .wrapper {
          padding: $px16 $px24;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: $colorBackground;
          border: $px1 solid #fff;
          border-radius: $px16;
          cursor: pointer;
          gap: $px8;
          transition: 0.18s;

          > .img {
            width: $px90;
            padding-top: 100%;
          }


          &.selected, &:hover {
            border-color: $colorPoint;
          }
        }
      }

      &.fundingIntro {
        > .wrapper {
          position: relative;
        }
      }

      &.rewardIntro {
        > .wrapper {
          position: relative;

          &:not(:first-child) {
            margin-top: $px16;
          }
        }
      }

      &.rewardConstructor {
        > .row {
          align-items: center;

          .order {
            text-align: center;

            .handle {
              cursor: pointer;
              padding: $px8 $px16;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
          }

          &.lead {
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;

      > .content {
        .video {
          .top {
            flex-direction: column;
            align-items: flex-start;
          }

          .bottom {
            margin-top: $px16;
          }
        }

        .select {
          > .wrapper {
            > .img {
              width: $px76;
            }
          }
        }
      }
    }
  }
}
</style>